<template>
  <b-col
    :lg="whichPage === 'listings' || whichPage === 'list_event' ? 12 : 4"
    class="d-flex align-items-center auth-bg px-2 p-lg-2"
  >
    <b-col
      sm="8"
      md="6"
      lg="12"
      class="px-xl-2 mx-auto"
    >
      <b-card-title
        title-tag="h2"
        class="font-weight-bold mb-1"
      >
        Sign In
      </b-card-title>
      <b-card-text class="mb-2">
        Welcome back, please sign in to your account.
      </b-card-text>

      <!-- form -->
      <validation-observer ref="loginForm">
        <b-form
          class="auth-login-form mt-2"
          @submit.prevent
        >
          <!-- email -->
          <b-form-group
            label="Email Address/Phone Number"
            label-for="login-email"
          >
            <validation-provider
              #default="{ errors }"
              name="Email Address/Phone Number"
              rules="required|loginId"
            >
              <b-form-input
                id="login-email"
                v-model="userEmail"
                :state="errors.length > 0 ? false : null"
                name="login-email"
                placeholder="user@example.com or 87654321"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- forgot password -->
          <b-form-group>
            <div class="d-flex justify-content-between">
              <label for="login-password">Password</label>
              <b-link
                v-if="!isHiringManager"
                :to="
                  userEmail.length > 4
                    ? '/auth/forgot?email=' + userEmail
                    : '/auth/forgot'
                "
              >
                <small>Forgot Password?</small>
              </b-link>
            </div>
            <validation-provider
              #default="{ errors }"
              name="Password"
              rules="required"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-form-input
                  id="login-password"
                  v-model="password"
                  :state="errors.length > 0 ? false : null"
                  class="form-control-merge"
                  :type="passwordFieldType"
                  name="login-password"
                  placeholder="············"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    class="cursor-pointer"
                    :icon="passwordToggleIcon"
                    @click="togglePasswordVisibility"
                  />
                </b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- checkbox -->
          <b-form-group v-if="!isHiringManager">
            <b-form-checkbox
              id="remember-me"
              v-model="rememberMe"
              name="checkbox-1"
            >
              Remember Me
            </b-form-checkbox>
          </b-form-group>

          <!-- submit buttons -->
          <SpinnerBtn v-if="isLoading" />
          <b-button
            v-else
            :disabled="loginText === 'Sign In' ? false: true"
            type="submit"
            variant="primary"
            block
            @click="validationForm"
          >
            {{ loginText }}
          </b-button>
        </b-form>
      </validation-observer>

      <b-card-text
        v-if="whichPage == 'normal' && !isHiringManager"
        class="m-0 p-0 text-center mt-2"
      >
        <span>New on our platform? </span>
        <b-link :to="{ name: 'auth-registration' }">
          <span>&nbsp;Create an account</span>
        </b-link>
      </b-card-text>

      <!-- <div class="py-1 d-flex justify-content-center align-items-center w-100">
      <hr style="margin-block: 2rem;">
      </div> -->
      <div v-if="false">
        <MyInfoButton
          :user-id="null"
        />
      </div>
    </b-col>
  </b-col>
</template>

<script>
/* eslint-disable global-require */
import { find } from 'lodash'
import platform from 'platform'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BForm,
  BButton,
} from 'bootstrap-vue'
import { required, email, loginId } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import SpinnerBtn from '@/layouts/components/spinners/SpinnerButton.vue'
import useJwt from '@/auth/jwt/useJwt'
import { isUserLoggedIn } from '@/auth/utils'

import MyInfoButton from '@/components/MyInfo/MyInfoButton.vue'
// import MyInfoBizButton from '@/components/MyInfo/MyInfoBizButton.vue'

export default {
  components: {
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BForm,
    BButton,
    SpinnerBtn,

    ValidationProvider,
    ValidationObserver,

    MyInfoButton,
  },
  mixins: [togglePasswordVisibility],
  props: {
    whichPage: { type: String, default: 'normal' },
    eventId: { type: String, default: '' },
    isHiringManager: { type: Boolean, default: false },
  },
  data() {
    return {
      newSession: false,
      rememberMe: false,
      password: '',
      userEmail: '',
      isLoading: false,
      score: 1.0,
      loginText: 'Sign In',
      required,
      email,
      loginId,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  watch: {
    score(old, newVal) {
      if (newVal < 0.4) {
        this.loginText = 'Refresh this page or try again later'
      }
    },
  },
  created() {
    // this.recaptcha()
  },
  mounted() {
    const queries = this.$route.query

    if (this.$isNotEmpty(queries.myinfo_login_id) && this.$isNotEmpty(queries.myinfo_login_verifier_code)) {
      this.initializeJWT('Unknown', 'Unknown', queries)
    }
  },
  methods: {
    async recaptcha() {
      // (optional) Wait until recaptcha has been loaded.
      await this.$recaptchaLoaded()

      // Execute reCAPTCHA with action "login".
      const token = await this.$recaptcha('login')
      // console.log(token)
      const recaptch = this.$recaptchaInstance
      recaptch.hideBadge()

      this.$http
        .get(`/api/auth/recaptcha/${token}`)
        .then(response => {
          const responseData = response.data
          if (responseData.success) {
            const userScore = responseData.score
            this.score = userScore
            if (this.score < 0.4) {
              this.loginText = 'Refresh this page or try again later'
            }
          }
        }).catch(error => {
        })

      // Do stuff with the received token.
    },
    alreadyLoggedInNotifier() {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Login Attempt',
            icon: 'AlertCircleIcon',
            text: 'You are already logged in!',
            variant: 'warning',
          },
        },
        { timeout: this.$longestTimeout },
      )
    },
    checkLogin() {
      // If user is already logged in notify
      if (isUserLoggedIn() && !this.isHiringManager) {
        // Close animation if passed as this
        this.alreadyLoggedInNotifier()
        return false
      }
      if (this.isHiringManager) {
        if (
          this.userEmail.toLowerCase()
          === this.$store.state.auth.ActiveUser.email_address.toLowerCase()
        ) {
          this.alreadyLoggedInNotifier()
          return false
        }
        const OtherProfiles = JSON.parse(localStorage.getItem('OtherProfiles'))
          || this.$store.state.OtherProfiles

        const result = find(OtherProfiles, {
          email_address: this.userEmail.toLowerCase(),
        })

        if (result) {
          if (result.email_address === this.userEmail.toLowerCase()) {
            this.alreadyLoggedInNotifier()
          } else {
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'Login Attempt',
                  icon: 'AlertCircleIcon',
                  text: 'You have already logged in to this account.',
                  variant: 'warning',
                },
              },
              { timeout: this.$longestTimeout },
            )
          }
          return false
        }
        return true
      }
      return true
    },
    twoFactorChecker() {
      localStorage.setItem('email', this.userEmail)
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Account Authentication',
          icon: 'AlertCircleIcon',
          text: 'Please check your email address to get your two factor code.',
          variant: 'danger',
        },
      })

      if (!this.isHiringManager) {
        this.$router.push('/authorise')
      } else {
        throw new Error('Two factor code required')
      }
    },
    confirmAccountChecker(userData) {
      let appliedJob = localStorage.getItem('applied_job')
      appliedJob = appliedJob ? JSON.parse(localStorage.getItem('applied_job')) : null

      if (this.isHiringManager) {
        this.$store.commit('auth/UPDATE_MULTIPLE_PROFILES', userData, {
          root: true,
        })
      } else {
        // Set accessToken
        useJwt.setToken(userData.token)
        this.$emit('login-state', true)
        // localStorage.setItem('accessToken', userData.token) // Update user details
        this.$store.commit('auth/UPDATE_USER_INFO', userData, { root: true }) // Set bearer token in axios

        let link = '/'
        if (
          userData.account_type === this.$AccountTypes.EMPLOYER
          || userData.account_type === this.$AccountTypes.TEMPORARY
        ) {
          link = '/events-manager/events'
        }
        // else if (appliedJob) {
        //   link = `/events/${appliedJob._id}?quiz=true`
        // }
        if (this.whichPage !== 'normal') {
          // this.$router.push(this.$router.currentRoute.query.redirect || link)
          if (this.$route.name.includes('listings-page') || this.$route.name === 'page-event') {
            this.$emit('user-logged-in')
          }
        } else if (this.$route.name !== 'app-login') {
          this.$router.push(this.$router.currentRoute.query.redirect || link)
        } else {
          this.$router.push(`${this.$route.fullPath}&approved=true`)
        }
      }
    },
    sessionChecker() {
      this.$bvModal
        .msgBoxConfirm(
          'We have detected that you are signed in on another device/ browser. Do you want to sign in on this device / browser and log out the other one ? ',
          {
            title: 'Multiple sessions alert',
            size: 'sm',
            okVariant: 'primary',
            okTitle: 'Yes',
            cancelTitle: 'No',
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          },
        )
        .then(value => {
          if (value) {
            this.$socket.client.emit('force_logout', {
              email_address: this.userEmail,
              user_agent: navigator.userAgent,
            })

            // this.newSession = true
            this.initializeJWT('Unknown', 'Unknown')
            this.$emit('login-state', true)
          } else {
            localStorage.removeItem('userInfo')
            localStorage.removeItem('accessToken')
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'Multiple Sessions Alert',
                  icon: 'AlertCircleIcon',
                  text: 'You have cancelled signing in to this device / browser successfully.',
                  variant: 'success',
                },
              },
              { timeout: this.$longestTimeout },
            )
          }
        }).catch(err => {
          console.err(err)
        })
    },

    expiredPasswordChecker(userData) {
      localStorage.removeItem('userInfo')
      localStorage.removeItem('accessToken')
      this.$router.push(userData.link)
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Password Expiry',
            icon: 'AlertCircleIcon',
            text: 'Your previous password has already expired.You can reset your password below',
            variant: 'danger',
          },
        },
        { timeout: this.$longestTimeout },
      )
    },

    initializeJWT(location, ipAddress, queries = null) {
      const userDetails = {
        user: {
          email_address: this.userEmail,
          password: this.password,
          location,
          new_session: this.newSession,
          platform,
          ip_address: ipAddress,
          login_type: 'normal',
        },
      }

      console.log(queries)
      if (
        this.$isNotEmpty(queries)
          && this.$isNotEmpty(queries.myinfo_login_id)
          && this.$isNotEmpty(queries.myinfo_login_verifier_code)
      ) {
        userDetails.user.email_address = null
        userDetails.user.login_type = 'myinfo'
        userDetails.user.myinfo_login_id = queries.myinfo_login_id
        userDetails.user.myinfo_login_verifier_code = queries.myinfo_login_verifier_code
        userDetails.user.password = 'myinfo-login'
      }

      useJwt
        .login(userDetails)
        .then(response => {
          const userData = response.data
          this.isLoading = false
          if (response.data.deactivated) {
            this.$toastDanger('Authentication Error', response.data.message)
            return
          }

          // useJwt.setRefreshToken(response.data.refreshToken)
          if (!this.isHiringManager) {
            localStorage.removeItem('userInfo')
            localStorage.removeItem('accessToken')
          }
          if (userData.two_factor) {
            this.twoFactorChecker()
          } else if (userData.token) {
            this.confirmAccountChecker(userData)
            // if (userData.account_approved) {
            //   this.confirmAccountChecker(userData)
            // } else {
            //   this.$toast(
            //     {
            //       component: ToastificationContent,
            //       props: {
            //         title: 'Account Confirmation',
            //         icon: 'AlertCircleIcon',
            //         text: 'Before you can login please check your email address to confirm your account.',
            //         variant: 'warning',
            //       },
            //     },
            //     { timeout: this.$longestTimeout },
            //   )
            // }
          } else if (userData.expired) {
            this.expiredPasswordChecker(userData)
          } else if (userData.show_session) {
            this.sessionChecker(userDetails)
          } else if (userData.deactivated_on) {
            this.$toast(
              {
                component: this.$toastContent,
                props: {
                  title: 'Authentication Error',
                  icon: 'AlertCircleIcon',
                  text: userData.errors,
                  variant: 'danger',
                },
              },
              { timeout: this.$longestTimeout },
            )
          } else {
            this.$toast(
              {
                component: this.$toastContent,

                props: {
                  title: 'Authentication Error',
                  icon: 'AlertCircleIcon',
                  text: 'Wrong email or password',
                  variant: 'danger',
                },
              },
              { timeout: this.$longestTimeout },
            )
          }
        })
        .catch(error => {
          this.isLoading = false
          this.$toast(
            {
              component: this.$toastContent,

              props: {
                title: 'Authentication Error',
                icon: 'AlertCircleIcon',
                text: 'Wrong email or password',
                variant: 'danger',
              },
            },
            { timeout: this.$longestTimeout },
          )
        })
    },

    proceedLogin() {
      this.isLoading = true
      this.userEmail = this.userEmail.toLowerCase()
      this.initializeJWT()
    },

    validationForm() {
      // this.recaptcha()
      if (!this.checkLogin()) return

      this.$refs.loginForm.validate().then(success => {
        if (success) {
          this.proceedLogin()
        } else {
          this.isLoading = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Sign In Error',
              icon: 'AlertCircleIcon',
              text: 'Please correct all the form errors before proceeding',
              variant: 'danger',
            },
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
